export const SEND_ENUM = {
  enterAmountOfUSDWishToSend: "Enter the amount of USD you wish to send. ",
  instantTransferAt: "Instant transfers at zero fees.",
  youSend: "You Send",
  RUD: "RUD",
  recipientGets: "Recipient Gets",
  INR: "INR",
  proceedButton: "Proceed",
  newToApp: "New to ",
  XXX: " Payezy?",
  learnMore: "Learn more",
  USD: "USD",
  login: "Login",
  loginUsingGoogleAccount: "Log in quickly using one of your google accounts.",
  continueWithGoogle: "Continue with Google  ",
  fullName: "Full Name",
  nickName: "Nick Name",
  phone: "Phone",
  accountNumber: "Account Number",
  email: "Email",
  confirmAccountNumber: "Confirm Account Number",
  IFSCCode: "IFSC Code",
  enterTheDetailsOfReciep: "Enter the details of the recipient",
  pleaseBePatient:
    "Please be patient. Recipient will receive your funds in the next 48 hours. ",
  viewStatus: "Check Status",
  yourRecentTransaction: "Transfer History",
  ID: "ID",
  date: "Date",
  amount: "Amount",
  recipient: "Recipient",
  receivers: "Receives (INR)",
  accNum: "Account Number",
  status: "Status",
  pleaseLoginToContinue: "Please login to continue!",
  accountNum: "Account No.",

  Name: "Name",
  pleaseBePatientYourTransfer:
    "Your payment has been received. Your transfer order will be delivered in 48 hours. Thank you for your patience.",
  pleaseEnterANick: "Please enter a nick name to continue!",
  pleaseEnterAPhone: "Please enter a phone number to continue!",
  pleaseEnterAFullName: "Please enter a full name to continue!",
  pleaseEnterAAccountNumber: "Please enter an account number to continue!",
  pleaseConfirmAccountNumber: "Please confirm your account number to continue!",
  pleaseEnterAnIFSCCode: "Please enter a IFSC code to continue!",
  transferId: "Transfer ID",
  fee: "Fee",
  receiver: "Receiver",
  checkStatus: "Check status",
  logoutMessage: "Are you sure you want to Logout? ",
  logout: "Logout",
  cancel: "Cancel",
  transactionSuccesfull: "Transaction Successful",
  success: "Success",
  chain: "Chain",
  orderId: "Order ID",
  destination: "Destination",
  explorerButton: "View on explorer",
  walletCheck: "Check your wallet for USDT deposit.",
  cancelAndRefund: "Support",
  cancelledButton: "Cancelled",
  completedButton: "Settlement Completed",
  processingButton: "Processing",
  yourOrderCompleted: "Your transfer order has been delivered successfully at",
  youHaveCancelledThisOrder:
    "You have cancelled this transfer order. Please be patient while we process your refund. ",
  transactionInProgress: "Storage in progress..",
  paymentRecievedButton: "Awaiting settlement",
  addNewReceiverDetails: "Add New Recipient",
  backButton: "Back",
  cannotUseMultipleTransactionSameTime:
    "Cannot use multiple transaction at the same time.",
  pleaseTryAgainLater: " Please refresh and  try again!",
  awaitingSettlement: " Awaiting Settlement",
  bankTransfer: "Bank Transfer",
  cardPayment: "Card Payment",
  feeBreakDown: "Price Breakdown",
  bankTransferCharges: "Bank Transfer Charges (0.8%)",
  cardProcessingCharge: "Card Processing Charges (4%)",
  paymentReference: "INR Payment Reference",
  resubmit: "Re-submit",
  confirm: "Continue",
  paymentRef: "Payment Ref",
  time: "Time",
  closeButton: "Close",
  gettingMoney: "Getting Money to where it matters.",
  sendMoneyToIndia:
    "Send money to your loved ones and partners from USA to India.",
  with: "With",
  zeroFee: "$0",
  platformFee: " platform fees,",
  payezyIsTheCheapest: "Payezy is the cheapest money transfer platform in USA.",
  fullyTransparent: "Fully transparent",
  fastAndHasle: "Fast and hassle free",
  bestExchangeRate: "Best exchange rate",
  refreshButton: "Refresh",
  makingYourPayments: "Making your payments easy across borders",
  sendNow: "Send Now",
  joinWaitlist: "Join waitlist",
  whyPayezy: "Why Payezy?",
  features: "Features",
  fullyTransparentHeading: "Fully Transparent",
  fullyTransparentDefinition:
    "Send more money at much lower cost than what banks and other money remittance services offer. Our platform is fully transparent our fx rates and pricing.",
  fastAndEasy: "Fast and Easy",
  fastAndEasyDefinition:
    "Easy to setup and see your fund gets transferred to your recipient with only few steps.",
  bestFXRate: "Best FX rate",
  bestFXRateDefinition:
    "Send funds at Mid-market rate always. Our new approach to cross border payments allows us to without bypasses middlemen.",
  simpleWayToSendMoney: "Simple way to send money",
  chooseATransferAmount: "Choose a transfer amount",
  signUp: "Sign up ",
  addTransferDetails: "Add transfer details",
  deliveryOfFunds: "Delivery of funds",
  enterTheAmountYouWishToTransfer:
    "Enter the amount you wish to transfer and see transparent pricing and fx rate.",
  completeTheSimpleReg:
    "Complete the simple registration and KYC to ensure the security of your money transfer. ",
  enterTheDetailsOfReciever:
    "Enter the details of the receiver to confirm the transfer. ",
  seeTheTransferHappening:
    "See the transfer happening instantly at the best price.",
  FAQ: "FAQ",
  whatIsTransferFee: "What is the transfer fee?",
  whichCountriesAre: "Which countries are supported for remittance?",
  howToSendMoneyToIndia: "How to send money to India?",
  doesReciepientNeedToSignup: "Does the recipient need to sign up on payezy?",
  howMuchTimeItTakes: "How much time does it take?",
  whyIsKYCRequired: "Why is KYC required?",
  areTransactionDoneOne:
    "Are transaction done on payezy fully safe and compliant?",
  privacyPolicy: "Privacy Policy",
  termsOfUse: "Terms of Use",
  copyRight: "© 2024 Rariti, Inc.  All rights reserved.",
};
